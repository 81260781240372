/* You can add global styles to this file, and also import other style files */
$imonitor-orange: #F0552F;
$imonitor-blue: #194759;

.primary {
    background-color: $imonitor-orange !important;
    color: white !important;
}

.secondary {
    background-color: $imonitor-blue !important;
    color: white !important;
}

.card .card-header-danger .card-icon,
.card .card-header-danger .card-text,
.card .card-header-danger:not(.card-header-icon):not(.card-header-text),
.card.bg-danger,
.card.card-rotate.bg-danger .front,
.card.card-rotate.bg-danger .back {
    background: $imonitor-blue !important;

}


.sidebar[data-color=danger] li.active>a {

    background-color: $imonitor-orange !important;

}


.navbar .dropdown-item:hover,
.navbar .dropdown-item:focus {
    background-color: $imonitor-orange !important;


}

.material-icons {
    color: $imonitor-blue !important;
}

.sidebar .nav li.active>a,
.sidebar .nav li.active>a i {
    color: #fff !important;
}


.mat-ink-bar {
    background-color: $imonitor-blue !important;
}


::ng-deep  .logo {
    padding: 15px 0px !important;
    margin: 0 !important;
    display: block !important;
    position: relative !important;
    z-index: 4 !important; 
    height: 76px !important;
}

 .logo-imgSidbar {
    display: flex !important;
    flex-direction: row !important;
    align-content: center !important;
    flex-wrap: nowrap !important;
    justify-content: center !important;
    align-items: center !important;
}

@media (max-width: 991px) {
    .sidebar::before, .off-canvas-sidebar nav .navbar-collapse::before {
        background-color: white !important;
    }
}

